import React from 'react';
import { Link } from 'react-router-dom';
import BaseLayout from '../components/BaseLayout';
import blogs from '../data/blogs.json';
import { useTranslation } from 'react-i18next';

const BlogPage = () => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <div className="blog-section">
        <div className="banner">
          <h1>{t("blog.title")}</h1>
        </div>
        {blogs.map((blog) => {
          return (
            <div className="blog-card" key={blog.title}>
              <img className="blog-image" src="/static/smart_pototo.PNG" alt="Passport Photo" width="32px" height="42px" />
              <div className="text-overlay">
                <h2 className="blog-title">{blog.title}</h2>
                <Link to={`${blog.link}`} className="blog-read-more">Read More</Link>
              </div>
          </div>
          )
        })}
      </div>
    </BaseLayout>
  );
};

export default BlogPage;
